/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Amateur Sailor Retires from Around the World Race Due to Technical Problems"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "Amateur sailor Dafydd Hughes has made the difficult decision to retire from the Global Solo Challenge, an around the world race that involves sailing single-handedly. The race, which began in September 2022, is expected to last for approximately nine months. Unfortunately, Hughes encountered technical issues with his boat, forcing him to end his participation in the race prematurely."), "\n", React.createElement(_components.h2, null, "The Retirement"), "\n", React.createElement(_components.p, null, "Dafydd Hughes' retirement from the race was reported by BBC News and Yahoo News. While the specific nature of the technical problems has not been disclosed, it is clear that they were significant enough to prevent Hughes from continuing his journey. The BBC article provides some insight into the situation, mentioning that Hughes had initially set out with the goal of circumnavigating the globe on his boat. However, due to the unforeseen technical issues, he was left with no choice but to withdraw from the race."), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bbc.com/news/uk-wales-67817252"
  }, "BBC News - Amateur sailor quits race around the world after tech issues")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.yahoo.com/amateur-sailor-quits-race-around-101648106.html"
  }, "Yahoo News - Amateur sailor quits race around the world after tech issues")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
